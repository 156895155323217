var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./metabase.util.malli.registry.js");
'use strict';var HU,JU,KU,yma,OU,PU,QU,Ama,RU,SU,TU,VU,WU,XU,YU,ZU,$U,bV,cV,dV,eV,fV,iV,jV,zma;HU=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.lk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.he(c)}),a)};
$CLJS.IU=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.Ne.g($CLJS.xB,function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v);$CLJS.pe(t,$CLJS.ED(x,function(){return function(A){return $CLJS.dk.l(HU(A),$CLJS.tA,$CLJS.H([$CLJS.pi]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),e($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k);return $CLJS.ae($CLJS.ED(u,function(){return function(v){return $CLJS.dk.l(HU(v),$CLJS.tA,$CLJS.H([$CLJS.pi]))}}(u,k,f,b)),e($CLJS.Hc(k)))}return null}},null,null)}(a)}())};
JU=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xf,a],null)],null);for(var c=$CLJS.xf;;){var d=$CLJS.md(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.nd(a);a=function(t,u,v,x,A,C,G,K){return function(S){return $CLJS.Wf.j(v,$CLJS.cf.h(function(X,T,da,oa,Oa){return function(sb){var Ra=$CLJS.I(sb,0,null);sb=$CLJS.I(sb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yd.g(Oa,Ra),sb],null)}}(t,u,v,x,A,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.Yd.g(c,l)):a=$CLJS.sd(k)?a(k):$CLJS.rd(k)?a($CLJS.Al($CLJS.zr,k)):m}else return c}};KU=function(a,b,c){return $CLJS.ud(c)&&$CLJS.E.g(a,$CLJS.z(c))&&!$CLJS.Cd(b,$CLJS.J.g(c,2))};$CLJS.LU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.kk.g($CLJS.nD,$CLJS.dd)),$CLJS.ZO.h(a));return JU($CLJS.dk.l(a,$CLJS.DM,$CLJS.H([$CLJS.nU])),function(c){return KU($CLJS.qA,b,c)})};
$CLJS.MU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.kk.g($CLJS.vD,$CLJS.dd)),$CLJS.WE.h(a));return JU($CLJS.dk.l(a,$CLJS.DM,$CLJS.H([$CLJS.nU])),function(c){return KU($CLJS.WE,b,c)})};yma=function(a){function b(d){return $CLJS.df(c,$CLJS.H([$CLJS.DM.h(d)]))}function c(d){return $CLJS.ae($CLJS.SD.h(d),$CLJS.df(b,$CLJS.H([$CLJS.oK.h(d)])))}return $CLJS.n($CLJS.NU.h(a))?$CLJS.Pe(!0):$CLJS.Wg(b(a))};
OU=function(a){if($CLJS.rd(a))for(var b=$CLJS.Pe(!1),c=$CLJS.y(a),d=$CLJS.z(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.z(v),A=$CLJS.B(v),C=x,G=A,K=$CLJS.zz(m,yma(C));f=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Ta,function(S,X,T,da,oa,Oa){return function db(Ra,Wa){try{if($CLJS.ud(Wa)&&3===$CLJS.D(Wa))try{var Ka=$CLJS.F(Wa,0);if($CLJS.ce(Ka,$CLJS.gF))try{var lb=$CLJS.F(Wa,1);if($CLJS.n($CLJS.tO.h(lb)))try{var Ja=$CLJS.F(Wa,1);if(null!=Ja?Ja.C&256||$CLJS.yc===Ja.mf||(Ja.C?0:$CLJS.Xa($CLJS.ub,
Ja)):$CLJS.Xa($CLJS.ub,Ja))try{var bc=$CLJS.J.j(Ja,$CLJS.tO,$CLJS.CU);if($CLJS.n($CLJS.Oe(da)(bc))){var Kd=$CLJS.J.g(Ja,$CLJS.tO);$CLJS.F(Wa,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(Oa),": no join named ",$CLJS.xh.l($CLJS.H([Kd]))].join("")],null)}throw $CLJS.Z;}catch(gd){if(gd instanceof Error){var Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;
}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)return $CLJS.zU(db,Ra,Wa);throw Hd;}throw gd;}}}(f,k,l,K,m,t,u,v,x,A,C,G,b,0,a,a,c,d,e,d,e)($CLJS.xf,$CLJS.dk.l(C,$CLJS.DM,$CLJS.H([zma]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};PU=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);QU=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Ama=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);RU=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
SU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);TU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.UU=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);VU=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
WU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);XU=new $CLJS.r(null,"refs","refs",80480079,null);YU=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);ZU=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);$U=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.aV=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);bV=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);cV=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);dV=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);eV=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
fV=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.gV=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.NU=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.hV=new $CLJS.M(null,"filters","filters",974726919);iV=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);jV=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.kV=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);zma=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.Y(YU,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.aV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iP,$CLJS.Ta],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.$k],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lN,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.oD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FP,new $CLJS.h(null,1,[$CLJS.Ar,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fU],null)],null)],null));$CLJS.Y(cV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null));
$CLJS.Y(PU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.ti,1],null),cV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"Breakouts must be distinct"],null),new $CLJS.Cc(function(){return $CLJS.IU},QU,$CLJS.zg([$CLJS.Gi,$CLJS.U,$CLJS.Jj,$CLJS.fi,$CLJS.Ci,$CLJS.Vi,$CLJS.Uj,$CLJS.ei,$CLJS.Fj,$CLJS.Ij],[$CLJS.rU,RU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[XU],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.IU)?$CLJS.IU.H:null]))],null)],null));
$CLJS.Y(dV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.ti,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,":fields must be distinct"],null),new $CLJS.Cc(function(){return $CLJS.IU},QU,$CLJS.zg([$CLJS.Gi,$CLJS.U,$CLJS.Jj,$CLJS.fi,$CLJS.Ci,$CLJS.Vi,$CLJS.Uj,$CLJS.ei,$CLJS.Fj,$CLJS.Ij],[$CLJS.rU,RU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.P(null,
1,5,$CLJS.Q,[XU],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.IU)?$CLJS.IU.H:null]))],null)],null));$CLJS.Y(eV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.EE],null),$CLJS.Dj,$CLJS.yj],null)],null));
$CLJS.Y(bV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.ti,1],null),eV],null));
var lV=$CLJS.zz(function(a){a=$CLJS.z($CLJS.LU(a));if($CLJS.n(a)){var b=$CLJS.Bz(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.xh.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.z($CLJS.MU(a));if($CLJS.n(a)){var b=$CLJS.Bz(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.Y(fV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,2,[$CLJS.ts,"Valid references for a single query stage",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.hj);return lV(a)}],null),$CLJS.Oe(lV)],null));
$CLJS.Y($CLJS.kV,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.UU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DM,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZO,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.VE,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),PU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.HF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),dV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hV,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),bV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NQ,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uK],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eO,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NU,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TJ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Cd(a,$CLJS.HP)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,
new $CLJS.h(null,1,[$CLJS.ts,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Oe($CLJS.cD($CLJS.eO,$CLJS.NU))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fV],null)],null));$CLJS.Y(SU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NJ],null)],null)],null)],null));
$CLJS.Y(TU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TJ],null)],null)],null)],null));$CLJS.Y(WU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TU],null)],null));
$CLJS.Y(ZU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Oe($CLJS.zz($CLJS.eO,$CLJS.NU))],null)],null));$CLJS.Y($U,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ar,$CLJS.aV,$CLJS.UU],null));
$CLJS.Y(Ama,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,$U],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.yi,$CLJS.Vs],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kV],null)],null)],null)],null));
$CLJS.Y(jV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,$U],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.yi,$CLJS.Vs],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WU],null)],null)],null)],null));$CLJS.Y(VU,ZU);
$CLJS.Y(iV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,2,[$CLJS.ts,"Valid references for all query stages",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.hj);return OU.h?OU.h(a):OU.call(null,a)}],null),$CLJS.Oe(OU)],null));
$CLJS.Y($CLJS.kK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VU],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iV],null)],null));
$CLJS.Y($CLJS.KK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.gV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.UJ,$CLJS.$J],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kK],null)],null)],null),$CLJS.qma],null));