var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");
'use strict';$CLJS.h1=function(a,b){var c=$CLJS.FV(b);return $CLJS.n(c)?$CLJS.e_(a,c):$CLJS.c_(a,b)};$CLJS.i1=function(a){var b=$CLJS.XA(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.tO.h($CLJS.CD(a));case "metadata/column":return $CLJS.eZ.h(a);case "mbql/join":return $CLJS.SD.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
$CLJS.j1=function(a,b){return $CLJS.ha.j?$CLJS.ha.j("%s__%s",a,b):$CLJS.ha.call(null,"%s__%s",a,b)};$CLJS.k1=function(a,b){var c=$CLJS.i1(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.O(b);c=$CLJS.J.g(d,$CLJS.WJ);d=$CLJS.J.g(d,$CLJS.XJ);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.h1(a,d),$CLJS.n(d))){d=$CLJS.U.h(d);a=$CLJS.U.h($CLJS.Z0(a,c));a=$CLJS.ha.j?$CLJS.ha.j("%s__via__%s",d,a):$CLJS.ha.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.j1(a,$CLJS.U.h(b)):$CLJS.U.h(b)};
$CLJS.l1=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.XJ),c);return $CLJS.Wf.j($CLJS.xf,$CLJS.kk.l($CLJS.ef($CLJS.LY),$CLJS.ef($CLJS.Ji),$CLJS.ef($CLJS.kk.g($CLJS.Yk,$CLJS.Ji)),$CLJS.H([$CLJS.cf.h(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.Ji),l=$CLJS.J.g(f,$CLJS.LY);return $CLJS.R.l($CLJS.Z0(a,l),$CLJS.B_,k,$CLJS.H([$CLJS.C_,$CLJS.eZ.h(f)]))}),$CLJS.lk.h(function(f){return $CLJS.Cd(e,$CLJS.XJ.h(f))}),$CLJS.dD(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.XJ),l=$CLJS.J.g(f,
$CLJS.B_),m=$CLJS.J.g(f,$CLJS.C_);f=$CLJS.c_(a,k);return function v(u){return new $CLJS.ie(null,function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.vd(x)){var A=$CLJS.ic(x),C=$CLJS.D(A),G=$CLJS.le(C);return function(){for(var X=0;;)if(X<C){var T=$CLJS.hd(A,X),da=$CLJS.R.l(T,$CLJS.WJ,l,$CLJS.H([$CLJS.y_,m,$CLJS.KJ,$CLJS.GY,$CLJS.uY,$CLJS.U.h(T)]));T=G;var oa=$CLJS.R,Oa=oa.j,sb=da;da=$CLJS.k1(a,da);da=d.h?d.h(da):d.call(null,da);oa=Oa.call(oa,sb,$CLJS.KY,da);T.add(oa);X+=1}else return!0}()?$CLJS.oe($CLJS.qe(G),
v($CLJS.jc(x))):$CLJS.oe($CLJS.qe(G),null)}var K=$CLJS.z(x),S=$CLJS.R.l(K,$CLJS.WJ,l,$CLJS.H([$CLJS.y_,m,$CLJS.KJ,$CLJS.GY,$CLJS.uY,$CLJS.U.h(K)]));return $CLJS.ae($CLJS.R.j(S,$CLJS.KY,function(){var X=$CLJS.k1(a,S);return d.h?d.h(X):d.call(null,X)}()),v($CLJS.Hc(x)))}return null}},null,null)}($CLJS.W_.v(a,b,f,new $CLJS.h(null,2,[$CLJS.G_,d,$CLJS.A_,!1],null)))})])),c)};$CLJS.m1=function(a,b){return $CLJS.c1.g(a,b)};$CLJS.n1=function(a){return $CLJS.eD("Question {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))]))};
$CLJS.o1=function(a,b){var c=$CLJS.OD.h(b);$CLJS.n(c)||(c=$CLJS.RY.h(b),$CLJS.n(c)||(c=$CLJS.QY.h(b),c=null!=c?$CLJS.V_.h($CLJS.m1(a,$CLJS.WX.h(c))):null));return $CLJS.n(c)?(c=$CLJS.Be($CLJS.sd(c)?$CLJS.BV.h(c):$CLJS.rd(c)?c:null),$CLJS.n(c)?$CLJS.mk.g($CLJS.Xs(Spa,a,b),c):null):null};$CLJS.p1=new $CLJS.M("metabase.lib.card","force-broken-id-refs","metabase.lib.card/force-broken-id-refs",-868945458);$CLJS.q1=new $CLJS.M(null,"field-ref","field-ref",-1699303428);$CLJS.L_.m(null,$CLJS.fK,function(a,b,c){return $CLJS.zz($CLJS.jD,$CLJS.U)(c)});$CLJS.S_.m(null,$CLJS.fK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);c=$CLJS.J.g(a,$CLJS.jD);return $CLJS.Sa(c)?$CLJS.R.j(a,$CLJS.jD,$CLJS.g1.g($CLJS.f1,b)):a});
$CLJS.W_.m(null,$CLJS.fK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.OD),f=$CLJS.J.g(c,$CLJS.RY);d=$CLJS.O(d);var k=$CLJS.J.g(d,$CLJS.A_),l=$CLJS.J.g(d,$CLJS.G_);return $CLJS.bf.g($CLJS.V_.v(a,b,c,d),$CLJS.n(k)?$CLJS.l1(a,b,$CLJS.bf.g(e,f),l):null)});$CLJS.O_.m(null,$CLJS.NU,function(a,b){var c=$CLJS.EV(a,b);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.NU);if($CLJS.n(c)){var d=$CLJS.e_(a,c);a=$CLJS.n(d)?$CLJS.k_.v(a,b,d,$CLJS.l_):null;return $CLJS.n(a)?a:$CLJS.n1(c)}return null});
var Spa=function(){function a(d,e,f){var k=$CLJS.dk.g($CLJS.Ez(f,$CLJS.eA),$CLJS.q1);f=$CLJS.Ck.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.tA,$CLJS.ej,$CLJS.Vs,$CLJS.IJ],null),function(){var l=$CLJS.Ji.h(k);if($CLJS.n(l))try{return $CLJS.Z0(d,l)}catch(m){return null}else return null}(),k,new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.IJ,$CLJS.KJ,$CLJS.OY,$CLJS.uY,$CLJS.zz($CLJS.uY,$CLJS.U)(k)],null)]));f=$CLJS.n(e)?$CLJS.R.j(f,$CLJS.YY,$CLJS.V0(e)):f;e=$CLJS.n($CLJS.n(!0)?$CLJS.Sa(e)||$CLJS.Sa($CLJS.CY.h($CLJS.e_(d,
$CLJS.V0(e)))):!0)?$CLJS.R.j(f,$CLJS.p1,!0):f;return $CLJS.jk.g($CLJS.vj.h(k),$CLJS.Xh)?$CLJS.R.j(e,$CLJS.LY,null):e}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.U_.m(null,$CLJS.fK,function(a,b,c,d){b=$CLJS.O(d);var e=$CLJS.J.g(b,$CLJS.G_);return $CLJS.mk.g(function(f){var k=$CLJS.qB($CLJS.KY,$CLJS.uY,$CLJS.U)(f);return $CLJS.R.j(f,$CLJS.KY,e.h?e.h(k):e.call(null,k))},$CLJS.o1(a,c))});