var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var LI;$CLJS.JI=function(a){return $CLJS.n($CLJS.qB($CLJS.rd,$CLJS.qd,$CLJS.Xk)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.KI=function(a,b){return $CLJS.Me($CLJS.zd,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.ie(null,function(K,S,X,T){return function(){for(var da=C;;){var oa=$CLJS.y(da);if(oa){var Oa=oa,sb=$CLJS.z(Oa);if(oa=$CLJS.y(function(Ra,Wa,db,Ka,lb,Ja,bc,Kd){return function Rh(gd){return new $CLJS.ie(null,function(pz,Il,Un,xr,Q_,Lv){return function(){for(;;){var Mv=
$CLJS.y(gd);if(Mv){if($CLJS.vd(Mv)){var Nv=$CLJS.ic(Mv),qz=$CLJS.D(Nv),Ek=$CLJS.le(qz);a:for(var yr=0;;)if(yr<qz){var gt=$CLJS.hd(Nv,yr);gt=$CLJS.E.g(Lv,$CLJS.VD)||$CLJS.E.g(Un,$CLJS.VD)||$CLJS.wE(Lv,gt)&&$CLJS.wE(Un,gt);Ek.add(gt);yr+=1}else{Nv=!0;break a}return Nv?$CLJS.oe($CLJS.qe(Ek),Rh($CLJS.jc(Mv))):$CLJS.oe($CLJS.qe(Ek),null)}Ek=$CLJS.z(Mv);return $CLJS.ae($CLJS.E.g(Lv,$CLJS.VD)||$CLJS.E.g(Un,$CLJS.VD)||$CLJS.wE(Lv,Ek)&&$CLJS.wE(Un,Ek),Rh($CLJS.Hc(Mv)))}return null}}}(Ra,Wa,db,Ka,lb,Ja,bc,
Kd),null,null)}}(da,K,sb,Oa,oa,S,X,T)($CLJS.gE)))return $CLJS.bf.g(oa,G($CLJS.Hc(da)));da=$CLJS.Hc(da)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.JI($CLJS.JD(b)))))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.JI($CLJS.JD(a)))}())};
LI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.Ne.j($CLJS.sE,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);return $CLJS.Le($CLJS.hl,
$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.KI($CLJS.J.g(k,m),$CLJS.J.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Hc(d);return c(e,d)};b.l=c;return b}()};$CLJS.MI=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.NI=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.OI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var PI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kr,$CLJS.dr],null)),QI=null,RI=0,SI=0;;)if(SI<RI){var Lia=QI.X(null,SI);$CLJS.vE(Lia,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.ti,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null)],null)],null)]));SI+=1}else{var TI=$CLJS.y(PI);if(TI){var UI=TI;if($CLJS.vd(UI)){var VI=$CLJS.ic(UI),Mia=$CLJS.jc(UI),
Nia=VI,Oia=$CLJS.D(VI);PI=Mia;QI=Nia;RI=Oia}else{var Pia=$CLJS.z(UI);$CLJS.vE(Pia,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.ti,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)],null)],null)],null)]));PI=$CLJS.B(UI);QI=null;RI=0}SI=0}else break}$CLJS.tE($CLJS.fr,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null)]));
for(var WI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.wF],null)),XI=null,YI=0,ZI=0;;)if(ZI<YI){var Qia=XI.X(null,ZI);$CLJS.vE(Qia,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.ti,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)],null)],null)],null)]));ZI+=1}else{var $I=$CLJS.y(WI);if($I){var aJ=$I;if($CLJS.vd(aJ)){var bJ=$CLJS.ic(aJ),Ria=$CLJS.jc(aJ),
Sia=bJ,Tia=$CLJS.D(bJ);WI=Ria;XI=Sia;YI=Tia}else{var Uia=$CLJS.z(aJ);$CLJS.vE(Uia,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.ti,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)],null)],null)],null)]));WI=$CLJS.B(aJ);XI=null;YI=0}ZI=0}else break}
for(var cJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dq,$CLJS.Fq,$CLJS.zq,$CLJS.Bq],null)),dJ=null,eJ=0,fJ=0;;)if(fJ<eJ){var Via=dJ.X(null,fJ);$CLJS.rE.l(LI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Via,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)]));fJ+=1}else{var gJ=$CLJS.y(cJ);if(gJ){var hJ=gJ;if($CLJS.vd(hJ)){var iJ=$CLJS.ic(hJ),Wia=$CLJS.jc(hJ),Xia=iJ,Yia=$CLJS.D(iJ);cJ=Wia;dJ=Xia;eJ=Yia}else{var Zia=
$CLJS.z(hJ);$CLJS.rE.l(LI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Zia,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)]));cJ=$CLJS.B(hJ);dJ=null;eJ=0}fJ=0}else break}
$CLJS.rE.l(LI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.IE,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)]));
$CLJS.rE.l(LI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.OE,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)]));
for(var jJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HE,$CLJS.TE],null)),kJ=null,lJ=0,mJ=0;;)if(mJ<lJ){var $ia=kJ.X(null,mJ);$CLJS.tE($ia,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)]));mJ+=1}else{var nJ=$CLJS.y(jJ);if(nJ){var oJ=nJ;if($CLJS.vd(oJ)){var pJ=$CLJS.ic(oJ),aja=$CLJS.jc(oJ),bja=pJ,cja=$CLJS.D(pJ);jJ=aja;kJ=bja;lJ=cja}else{var dja=$CLJS.z(oJ);$CLJS.tE(dja,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)]));jJ=$CLJS.B(oJ);
kJ=null;lJ=0}mJ=0}else break}
for(var qJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JE,$CLJS.QE],null)),rJ=null,sJ=0,tJ=0;;)if(tJ<sJ){var eja=rJ.X(null,tJ);$CLJS.tE(eja,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null)]));tJ+=1}else{var uJ=$CLJS.y(qJ);if(uJ){var vJ=uJ;if($CLJS.vd(vJ)){var wJ=$CLJS.ic(vJ),fja=$CLJS.jc(vJ),gja=wJ,hja=$CLJS.D(wJ);qJ=fja;rJ=gja;sJ=hja}else{var ija=$CLJS.z(vJ);$CLJS.tE(ija,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null)]));qJ=$CLJS.B(vJ);
rJ=null;sJ=0}tJ=0}else break}
for(var xJ=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oF,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.tr],null)],null),yJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.YE,$CLJS.rF,$CLJS.fF,$CLJS.ZE],null)),zJ=null,AJ=0,BJ=0;;)if(BJ<AJ){var CJ=zJ.X(null,BJ);$CLJS.FF.v(CJ,$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,CJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.tD,xJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)],null));BJ+=1}else{var DJ=$CLJS.y(yJ);if(DJ){var EJ=DJ;if($CLJS.vd(EJ)){var FJ=$CLJS.ic(EJ),jja=$CLJS.jc(EJ),kja=FJ,lja=$CLJS.D(FJ);yJ=jja;zJ=kja;AJ=lja}else{var GJ=$CLJS.z(EJ);$CLJS.FF.v(GJ,$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,GJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.tD,xJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.cE],null)],null));yJ=$CLJS.B(EJ);zJ=null;AJ=0}BJ=0}else break}
$CLJS.FF.v($CLJS.dB,$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.dB],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.tD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Kk,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.tr],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.Qu,$CLJS.Vw,$CLJS.NE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XF],null)],null));$CLJS.FF.v($CLJS.EE,$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.EE],null),$CLJS.tD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.gD,$CLJS.oD],null)],null));
$CLJS.Y($CLJS.MI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.NI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$E,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.ar,$CLJS.Gq,$CLJS.wF,$CLJS.OE,$CLJS.IE,$CLJS.Dq,$CLJS.zq,$CLJS.Fq,$CLJS.Bq,$CLJS.HE,$CLJS.TE,$CLJS.JE,$CLJS.QE,$CLJS.fF,$CLJS.ZE,$CLJS.YE,$CLJS.rF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OI,$CLJS.Hi],null)],null));